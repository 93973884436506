const HttpBackend = require("i18next-http-backend/cjs")
const ChainedBackend = require("i18next-chained-backend").default
const LocalStorageBackend = require("i18next-localstorage-backend").default

const isBrowser = typeof window !== "undefined"

const backend = []
const backendOptions = []

if (process.env.NODE_ENV !== "development") {
  backend.push(LocalStorageBackend)
  backendOptions.push({
    prefix: "trans_",
    expirationTime: 7 * 24 * 60 * 60 * 1000, // 1 week
    defaultVersion: "24.05.19.33113",
  })
}

backend.push(HttpBackend)
backendOptions.push({
  allowMultiLoading: true,
  queryStringParams: { v: "24.05.19.33113" },
})

/**
 * @type {import("next-i18next").UserConfig}
 */
module.exports = {
  debug: false, // process.env.NODE_ENV === "development",
  i18n: {
    defaultLocale: "en-US",
    locales: process.env.NEXT_PUBLIC_ENV === 'production' ? ["en-US", "fr-FR"] : ["en-US", "fr-FR", "es-US", "es-ES", "it-IT"],
    localeDetection: false, // disable automatic redirection on the user's preferred locale
  },
  ns: ["translation", "error", "pa", "stripe", "meta", "desc", "com", "custom"],
  supportedLngs: process.env.NEXT_PUBLIC_ENV === 'production' ? ["en-US", "fr-FR"] : ["en-US", "fr-FR", "es-US", "es-ES", "it-IT"],
  defaultNS: "translation",
  fallbackLng: "en-US",
  backend: {
    backendOptions: backendOptions,
    backends: isBrowser ? backend : [],
  },
  reloadOnPrerender: process.env.NODE_ENV === "development",
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
}
